/* WelcomeHero.css */
.blurred-hero {
  backdrop-filter: blur(4px);
}

.hero {
  position: sticky;
  top: 0;
  z-index: 5;
}

.hero-body {
  position: relative;
  padding: 5rem;
  background-color: rgba(0, 0, 0, 0.3);
  user-select: none;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.logo {
  display: flex;
  justify-content: center;
}

.logo img {
  max-width: 40rem;
  min-width: 20rem;
  mix-blend-mode: difference;
  height: auto;
  pointer-events: none;
}

.hero-body .title {
}

.hero-body .subtitle {
  color: #d5d5d5d5;
  text-align: center;
  font-size: 3rem;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 3.9rem */
  letter-spacing: -0.03rem;

  mix-blend-mode: difference;
}

.hero-body p {
}
